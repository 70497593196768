.App {
  text-align: center;
  padding: 40px 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #e0e6ed;
  color: #333;
}

.App-header {
  background-color: #1c1f24;
  padding: 80px 30px;
  color: #ffffff;
  margin-bottom: 60px;
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  letter-spacing: 2px;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.project-card {
  background: #ffffff;
  border-radius: 16px;
  padding: 32px;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.project-card img {
  max-width: 100%;
  border-radius: 12px;
  margin-bottom: 24px;
  transition: transform 0.3s ease-in-out;
}

.project-card h3 {
  margin-top: 16px;
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 600;
  color: #1c1f24;
  letter-spacing: 1px;
}

.project-card p {
  font-size: 15px;
  color: #7a7f85;
  margin-bottom: 20px;
  line-height: 1.6;
}

.project-card a {
  display: inline-block;
  margin-top: auto;
  padding: 10px 20px;
  text-decoration: none;
  color: #ffffff;
  background-color: #ff6f61;
  border-radius: 8px;
  font-weight: 600;
  transition: background-color 0.3s ease-in-out;
}

.project-card a:hover {
  background-color: #ff3d2e;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.project-card:hover img {
  transform: scale(1.05);
}
